<template>
  <div class="">
    <v-row justify="center">
      <v-col class="text-center" cols="12" sm="6">
        <v-img contain :src="img" max-height="320px" max-width="320px" class="rounded-circle mx-auto"></v-img>
      </v-col>
      <v-col class="text-center" cols="12" sm="6" >
        
  
      <h1>Awards:</h1>  

  <p><strong>Olawale Adekola</strong> is an award winning photographer both local and international awards. Here are a few of his accolades.
</p>
          <ol class="text-left">
          <li class="title mb-3">
            3rd runner-up, Wiki Loves Monument in Nigeria for (Wikipedia), Nigeria, 2019.
          </li>
          <li class="title mb-3">
            Top 50 Lagos Photographers, 35awards 4th International Photography Awards, Moscow, 2019.
          </li>
          <li class="title mb-3">
            Top Finalist, Skill In Action Photo-Competition, World Youth Skill Day(WYSD), UNESCO-UNEVOC, Germany, 15th July, 2019.
          </li>
          <li class="title mb-3">
            Top best 5% Straight Portrait (Single Photo), 35awards Annual Photography Awards, Russia, Moscow, 2018.
          </li>
          <li class="title mb-3">
            Top 20 Lagos and Nigeria Photographers , 35awards, 3rd International Annual Photo Awards, Moscow, 2017.
          </li>
          <li class="title mb-3">
            Nikon Prize , Nikon 100th Anniversary, Nigeria, 2017.
          </li>
          </ol>
</v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      img:"uploads/1c2b65ff-f4c7-46a9-bc77-cca38dd3dcea.jpg"
    }
  },
};
</script>
